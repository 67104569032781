<template>
    <div>
        <Table
            ref="table"
            :tableTitle="tableTitle"
            :search-url="url"
            :back="back"
            :form="form"
            :delete-url="deleteUrl"
            :add="true"
            @add="add()"
        >
        </Table>
        <el-dialog class="new-dialog" title="添加检测人员" :visible.sync="dialogFormVisible" width="30vw">
            <el-form :model="addForm">
                <el-form-item label="检测人员名称" label-width="100px"  >
                    <el-input v-model="addForm.name" autocomplete="off" placeholder="请输入检测人员名称"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false" round style="background-color: #8197FE; border-color: #8197FE;color: #FFFFFF;">取 消</el-button>
                <el-button type="primary" @click="onAdd" round style="background-color: #62B5FB; border-color: #62B5FB;">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Table from '../components/Table';

export default {
    name: "InspectionPersonnel",
    components: {
        Table
    },
    data() {
        return {
            addForm:{
                name:''
            },
            form       : {
                keyword: ''
            },
            tableTitle: [
                {
                    name: '编号',
                    value: 'num',
                    width : 130
                },
                {
                    name: '检测人员',
                    value: 'name',
                },
                {
                    name: '添加时间',
                    value: 'add_time',
                }
            ],

            back: {
                text: '返回列表'
            },
            url: 'api/patient/check_list',
            tableData: [],
            deleteUrl: 'api/patient/check_del',
            dialogFormVisible: false
        };
    },
    mounted() {
        console.log(this.$route.query.type)

    },
    created() {

    },
    methods:{
        add() {
            this.dialogFormVisible = true
        },
        async onAdd(){
            if(this.addForm.name == '') {
                this.$message({
                    type: 'info',
                    message: '请输入检测人员名称'
                });
                return;
            }
            await this.$axios.post('api/patient/check_add',this.$qs.stringify( this.addForm)).then(res => {
                if(res.data.code == 1){
                    this.$message.success(res.data.info)
                    this.$refs.table.onSubmit()
                    this.addForm.name = '';
                    this.dialogFormVisible = false
                }
            })
        },
    },
    computed: {},
    watch: {},
}
</script>

<style scoped lang="scss">
::v-deep {
    .el-dialog__header {
        text-align: left;
        border-bottom: 1px solid #E8E8E8;
        font-size: 28px;
        font-weight: 600;
        color: #333333;
        line-height: 40px;
        padding-left: 50px;
    }
}
.el-form {
    width: 500px;
}

</style>
